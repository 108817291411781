import { createSlice } from '@reduxjs/toolkit'

export const devMode = createSlice({
  name: 'devMode',
  initialState: {
    value:
            JSON.parse(window.localStorage.getItem('dev_mode'), null) || false
  },
  reducers: {
    setDevMode: (state, action) => {
      window.localStorage.setItem('dev_mode', action.payload)
      state.value = action.payload
    }
  }
})

export const { setDevMode } = devMode.actions

export default devMode.reducer
