import { createSlice } from '@reduxjs/toolkit'

export const autoCorrect = createSlice({
  name: 'autoCorrect',
  initialState: {
    value:
            JSON.parse(window.localStorage.getItem('auto_correct'), null) ??
            true
  },
  reducers: {
    setAutoCorrect: (state, action) => {
      window.localStorage.setItem('auto_correct', action.payload)
      state.value = action.payload
    }
  }
})

export const { setAutoCorrect } = autoCorrect.actions

export default autoCorrect.reducer
