import { createSlice } from '@reduxjs/toolkit'

export const currentUser = createSlice({
  name: 'currentUser',
  initialState: {
    value: null
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload
    },
    clearUser: (state) => {
      state.value = null
    }
  }
})

export const { setUser, clearUser } = currentUser.actions

export default currentUser.reducer
