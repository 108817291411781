import { createSlice } from '@reduxjs/toolkit'

export const errors = createSlice({
  name: 'errors',
  initialState: {
    value: []
  },
  reducers: {
    addError: (state, action) => {
      console.error(action.payload)
      state.value = [...state.value, action.payload]
    },
    popError: (state) => {
      state.value = state.value.slice(1)
    },
    clearError: (state) => {
      state.value = []
    }
  }
})

export const { addError, popError, clearError } = errors.actions

export default errors.reducer
