import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'

const DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

i18n.use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: DEV_MODE,
    backend: {
      backends: [
        DEV_MODE ? null : LocalStorageBackend,
        HttpBackend
      ].filter(Boolean),
      backendOptions: [
        DEV_MODE
          ? null
          : {
              expirationTime: 24 * 60 * 60 * 1000
            },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
      ].filter(Boolean)
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
