import { CircularProgress, IconButton, Sheet, Snackbar, Stack, Typography } from '@mui/joy'
import { useEffect, useState } from 'react'
import { LoginScreen } from './auth/LoginScreen'
import { useDispatch, useSelector } from 'react-redux'
import { popError } from '../redux/errorSlice'
import { Outlet, useParams } from 'react-router-dom'
import { Menu, Chat } from '@mui/icons-material'
import loadable from '@loadable/component'

const ChatServiceList = loadable(() => import('./navigation/ChatServiceList').then(i => i.ChatServiceList), {
  fallback: <CircularProgress />
})

const ChatList = loadable(() => import('./navigation/ChatList').then(i => i.ChatList), {
  fallback: <CircularProgress />
})

export const App = () => {
  const { chatId } = useParams()

  const [sideBarShown, setSidebarShown] = useState(!chatId)

  const dispatch = useDispatch()

  useEffect(() => {
    setSidebarShown(!chatId)
  }, [chatId])

  const errors = useSelector((state) => state.errors.value)

  return (
        <Stack
            direction="column"
            justifyContent="center"
            sx={{
              height: '100vh'
            }}
        >

            <Stack
                direction="row"
                   sx={{
                     display: {
                       sm: 'flex',
                       md: 'none'
                     },
                     gap: '1em',
                     alignItems: 'center'
                   }}>
                {chatId
                  ? <><IconButton
                    variant="plain"
                    color="neutral"
                    onClick={() => setSidebarShown(!sideBarShown)}
                >
                    {sideBarShown ? <Chat/> : <Menu/>}
                </IconButton>

                <Typography
                >Query Pilot</Typography></>
                  : null}
            </Stack>

            <Stack
                direction="row"
                component="main"
                sx={{
                  flexGrow: 1
                }}
            >
                <LoginScreen>
                    <Sheet
                        sx={{
                          display: {
                            xs: sideBarShown ? 'flex' : 'none',
                            sm: sideBarShown ? 'flex' : 'none',
                            md: 'flex'
                          },
                          flexGrow: { xs: 1, sm: 1, md: 0 }
                        }}
                    >
                        <ChatServiceList />
                        <ChatList />
                    </Sheet>
                    <Stack
                        direction="column"
                        sx={{
                          flexGrow: 1,
                          display: {
                            xs: sideBarShown ? 'none' : 'flex',
                            sm: sideBarShown ? 'none' : 'flex',
                            md: 'flex'
                          },
                          flex: 1,
                          minWidth: 0
                        }}
                    >
                        <Outlet />
                    </Stack>
                </LoginScreen>
                <Snackbar
                    open={errors?.length > 0}
                    autoHideDuration={4000}
                    onClose={() => {
                      dispatch(popError())
                    }}
                    variant="soft"
                    color="danger"
                >
                    {errors[0]}
                </Snackbar>
            </Stack>
        </Stack>
  )
}
