import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import loadable from '@loadable/component'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { client } from './client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import store from './redux/store'
import './i18n'
import { App } from './components/App'
import '@fontsource/inter'
import { CircularProgress, CssBaseline, CssVarsProvider, GlobalStyles, Sheet } from '@mui/joy'
import { ErrorElement } from './components/util/Error'
import { Landing } from './components/Landing'
import { queryPilotTheme } from './theme'
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  TimeSeriesScale,
  Tooltip
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

const ChatPane = loadable(() => import('./components/chat/ChatPane').then(i => i.ChatPane), {
  fallback: <CircularProgress />
})

const router = createBrowserRouter([{
  path: '/',
  element: <App />,
  errorElement: <ErrorElement />,
  children: [{
    path: '/', element: <Landing />
  }, {
    path: '/chat/:chatId', element: <ChatPane />
  }]
}])

const { REACT_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID } = process.env

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  PieController,
  DoughnutController,
  ArcElement,
  TimeSeriesScale,
  annotationPlugin
)

if (!GOOGLE_CLIENT_ID) {
  console.error('REACT_APP_GOOGLE_CLIENT_ID is missing')
}

const PageLoader = () => (<Sheet
    sx={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'
    }}
>
    <CircularProgress size="lg" />
</Sheet>)

const root = ReactDOM.createRoot(document.getElementById('app_root'))
root.render(<React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ApolloProvider client={client}>
            <ReduxProvider store={store}>
                <CssVarsProvider theme={queryPilotTheme} defaultMode="system">
                    <CssBaseline />
                    <GlobalStyles styles={theme =>
                      ({
                        '*::-webkit-scrollbar': {
                          background: theme.vars.palette.background.level1,
                          width: '0.5em'
                        },
                        '*::-webkit-scrollbar-thumb': {
                          background: theme.vars.palette.neutral[500]
                        }
                      })
                    } />
                    <Suspense fallback={<PageLoader />}>
                        <RouterProvider router={router} />
                    </Suspense>
                </CssVarsProvider>
            </ReduxProvider>
        </ApolloProvider>
    </GoogleOAuthProvider>
</React.StrictMode>)
reportWebVitals()
