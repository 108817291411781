import { createSlice } from '@reduxjs/toolkit'
import { currentProject } from './currentProjectSlice'

export const currentChatConfig = createSlice({
  name: 'currentChatConfig',
  initialState: {
    value: null
  },
  reducers: {
    setChatConfig: (state, { payload: chatConfig }) => {
      window.localStorage.setItem(
        'current_chat_config_id',
        chatConfig?.id
      )
      state.value = chatConfig
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      currentProject.actions.setProject,
      (state, { payload: project }) => {
        state.value = project?.chatConfigs?.length
          ? project?.chatConfigs[0]
          : null
      }
    )
  }
})

export const { setChatConfig } = currentChatConfig.actions

export default currentChatConfig.reducer
