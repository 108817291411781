import { createSlice } from '@reduxjs/toolkit'

export const currentProject = createSlice({
  name: 'currentProject',
  initialState: {
    value: null
  },
  reducers: {
    setProject: (state, { payload: project }) => {
      if (project?.id) {
        window.localStorage.setItem('current_project_id', project?.id)
      }
      state.value = project
    }
  }
})

export const { setProject } = currentProject.actions

export default currentProject.reducer
