import { useNavigate, useRouteError } from 'react-router-dom'
import { Sheet, Typography } from '@mui/joy'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ErrorElement = () => {
  const error = useRouteError()
  const navigate = useNavigate()

  const [timeoutCancel, setTimeoutCancel] = useState(null)

  const { t } = useTranslation('util/error')

  console.error(error)

  useEffect(() => {
    if (error?.status) {
      const cancel = setTimeout(() => {
        navigate('/')
      }, 1000)
      setTimeoutCancel(cancel)
    }

    return () => {
      clearTimeout(timeoutCancel)
    }
  }, [error])

  return (
        <Sheet
            sx={{
              height: '100vh',
              flexGrow: 1,
              bgcolor: 'background.level1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1em'
            }}
        >
            <Typography level="h1">
                {error?.status || t`error_reload`}
            </Typography>
        </Sheet>
  )
}
