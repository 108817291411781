import { configureStore } from '@reduxjs/toolkit'
import currentProjectSlice from './currentProjectSlice'
import currentChatConfigSlice from './currentChatConfigSlice'
import currentUserSlice from './currentUserSlice'
import devModeSlice from './devModeSlice'
import autoCorrectSlice from './autoCorrectSlice'
import errorsSlice from './errorSlice'
import querySlice from './querySlice'

export default configureStore({
  reducer: {
    currentProject: currentProjectSlice,
    currentChatConfig: currentChatConfigSlice,
    currentUser: currentUserSlice,
    devMode: devModeSlice,
    autoCorrect: autoCorrectSlice,
    errors: errorsSlice,
    queries: querySlice
  }
})
