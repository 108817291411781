import { Stack } from '@mui/joy'

export const Landing = () => {
  return (
        <Stack
            sx={{
              flexGrow: 1,
              bgcolor: 'background.level1'
            }}
        ></Stack>
  )
}
