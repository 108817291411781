import { extendTheme } from '@mui/joy'
import { github, hybrid } from 'react-code-blocks'

const lightPalette = {
  text: {
    primary: '#1e1e2d',
    secondary: '#1e1e2d',
    grid: '#2B2C40'
  },
  primary: {
    solidBg: '#8D99FF',
    solidHoverBg: '#acb1e1',
    text: '#FFFFFF',
    softBg: '#729fb8',
    softHoverBg: '#323C55',
    softActiveBg: '#171A28',
    outlinedHoverBg: '#DDE7EE',
    outlinedColor: '#474B5A'
  },
  neutral: {
    solidBg: '#729fb8',
    text: '#FFFFFF',
    solidHoverBg: '#9fcfea',
    softBg: '#729fb8',
    softHoverBg: '#FFFFFF'
  },
  danger: {
    solidBg: '#E54B4B',
    text: '#FFFFFF'
  },
  success: {
    solidBg: '#8ACB88',
    text: '#FFFFFF'
  },
  warning: {
    solidBg: '#EE964B',
    text: '#FFFFFF'
  },
  background: {
    body: '#F6F7FA',
    level1: '#FFFFFF'
  }
}

const darkPalette = {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    grid: '#ababc7'
  },
  primary: {
    solidBg: '#8D99FF',
    solidHoverBg: '#acb1e1',
    text: '#FFFFFF',
    softBg: '#4D6281',
    softHoverBg: '#323C55',
    softActiveBg: '#171A28',
    outlinedHoverBg: '#2B2C40'
  },
  neutral: {
    solidBg: '#86BBD8',
    text: '#FFFFFF',
    solidHoverBg: '#729fb8',
    outlinedActiveBg: '#323C55'
  },
  danger: {
    solidBg: '#E54B4B',
    text: '#FFFFFF'
  },
  success: {
    solidBg: '#8ACB88',
    text: '#FFFFFF'
  },
  warning: {
    solidBg: '#EE964B',
    text: '#FFFFFF'
  },
  background: {
    body: '#2B2C40',
    level1: '#252537',
    surface: '#1e1e2d'
  }
}

export const getChartTheme = colorscheme => {
  if (colorscheme === 'light') {
    return [
      '#3C1518',
      '#69140E',
      '#A44200',
      '#D58936',
      '#210B2C',
      '#55286F',
      '#BC96E6',
      '#AE759F'
    ].sort(() => 0.5 - Math.random())
  } else {
    return [
      '#ffc14d',
      '#b37f36',
      '#fffd4d',
      '#D58936',
      '#9999ff',
      '#bb99ff',
      '#BC96E6',
      '#AE759F'
    ].sort(() => 0.5 - Math.random())
  }
}

export const getCodeBockTheme = colorscheme => colorscheme === 'light' ? github : hybrid

export const queryPilotTheme = extendTheme({
  components: {
    JoySkeleton: {
      defaultProps: {
        animation: 'wave'

      }
    },
    JoyAvatar: {
      defaultProps: {
        variant: 'solid'
      }
    },
    JoyCircularProgress: {
      defaultProps: {
        sx: t => ({
          '--CircularProgress-trackColor': t.vars.palette.background.level2,
          '--CircularProgress-progressColor': t.vars.palette.solidBg
        })
      }
    }
  },
  colorSchemes: {
    light: { palette: lightPalette }, dark: { palette: darkPalette }
  }

})
