import { createSlice } from '@reduxjs/toolkit'

export const querySlice = createSlice({
  name: 'querySlice',
  initialState: {
    value: {
      currentDisplayQuery: null,
      queries: {}
    }
  },
  reducers: {
    clearQueries: (state) => {
      state.value = {
        currentDisplayQuery: null,
        queries: {}
      }
    },
    setQuery: (state, { payload: query }) => {
      state.value.queries[query.id] = query
    },
    setCurrentDisplayQuery: (state, { payload: queryId }) => {
      state.value.currentDisplayQuery = queryId
    },
    removeQuery: (state, { payload: query }) => {
      delete state.value.queries[query.id]
    }
  }
})

export const { setQuery, setCurrentDisplayQuery, clearQueries } = querySlice.actions

export default querySlice.reducer
