import { useDispatch } from 'react-redux'
import { CircularProgress, Sheet, Stack, Typography, useColorScheme } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ME_QUERY } from './UserAvatar'
import { useEffect } from 'react'
import { setUser } from '../../redux/currentUserSlice'
import MicrosoftLogin from 'react-microsoft-login'
import { addError } from '../../redux/errorSlice'

const { REACT_APP_MS_APP_ID } = process.env

if (!REACT_APP_MS_APP_ID) {
  console.error('REACT_APP_MS_APP_ID is missing')
}

const LOGIN_WITH_GOOGLE = gql`
    mutation LoginWithGoogle($credential: String!) {
        loginWithGoogle(credential: $credential) {
            ... on LoginError {
                message
            }
            ... on LoginSuccess {
                token
                expires
            }
        }
    }
`

const LOGIN_WITH_MICROSOFT = gql`
    mutation LoginWithMicrosoft($credential: String!) {
        loginWithMicrosoft(credential: $credential) {
            ... on LoginError {
                message
            }
            ... on LoginSuccess {
                token
                expires
            }
        }
    }
`

export const IS_LOGGED_IN_QUERY = gql`
    query IsLoggedIn {
        me {
            id
            name
            email
            image
            isSuperuser
        }
    }
 `

export const LoginScreen = ({ children }) => {
  const { systemMode, mode } = useColorScheme()
  const colorscheme = mode === 'system' ? systemMode : mode

  const dispatch = useDispatch()

  const { t } = useTranslation('auth/login_screen')

  const [loginWithGoogle] = useMutation(LOGIN_WITH_GOOGLE, {
    refetchQueries: [ME_QUERY]
  })

  const [loginWithMicrosoft] = useMutation(LOGIN_WITH_MICROSOFT, {
    refetchQueries: [ME_QUERY]
  })

  const { data: userData, loading } = useQuery(IS_LOGGED_IN_QUERY)

  useEffect(() => {
    if (!loading) {
      dispatch(setUser(userData?.me))
    }
  }, [userData?.me?.id, loading])

  const navigate = useNavigate()
  if (loading) {
    return (
            <Sheet
                sx={{
                  flexGrow: 1,
                  bgcolor: 'background.body',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1em'
                }}
            > <CircularProgress size="lg" />
            </Sheet>
    )
  }

  if (userData?.me?.id) {
    return children
  } else {
    return (
            <Sheet
                sx={{
                  flexGrow: 1,
                  bgcolor: 'background.body',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1em'
                }}
            >
                <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography level="h1">{t`app_name`}</Typography>
                    <div style={{ colorScheme: 'light' }}>
                        <GoogleLogin
                            width="330px"
                            shape="pill"
                            theme={colorscheme === 'light' ? 'filled_blue' : 'filled_black'}
                            logo_alignment="left"
                            size="large"
                            onSuccess={(credentialResponse) => {
                              loginWithGoogle({
                                variables: {
                                  credential: credentialResponse.credential
                                }
                              }).then(
                                ({
                                  data: {
                                    loginWithGoogle: { token, expires, message }
                                  }
                                }) => {
                                  const cookies = new Cookies()
                                  cookies.set('ssbi_session', token, {
                                    path: '/',
                                    expires: expires ? new Date(expires) : null,
                                    secure: true,
                                    sameSite: 'strict'
                                  })
                                  if (message) {
                                    dispatch(addError(message))
                                  } else {
                                    navigate(0)
                                  }
                                }
                              )
                            }}
                            onError={() => {
                              console.log('Login Failed')
                            }}
                            useOneTap
                            auto_select
                        />
                    </div>
                    <MicrosoftLogin
                        clientId={REACT_APP_MS_APP_ID}
                        graphScopes={['User.Read.All']}
                        authCallback={(error, { accessToken }) => {
                          if (error) {
                            dispatch(addError(error))
                          } else {
                            loginWithMicrosoft({
                              variables: {
                                credential: accessToken
                              }
                            }).then(
                              ({
                                data: {
                                  loginWithMicrosoft: { token, expires, message }
                                }
                              }) => {
                                const cookies = new Cookies()
                                cookies.set('ssbi_session', token, {
                                  path: '/',
                                  expires: expires ? new Date(expires) : null,
                                  secure: true,
                                  sameSite: 'strict'
                                })

                                if (message) {
                                  dispatch(addError(message))
                                } else {
                                  navigate(0)
                                }
                              }
                            )
                          }
                        }}
                        buttonTheme={colorscheme}
                        redirectUri={location.origin}
                        prompt="select_account"
                    />
                </Stack>
            </Sheet>
    )
  }
}
