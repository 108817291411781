import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, CircularProgress, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { clearUser, setUser } from '../../redux/currentUserSlice'
import { IS_LOGGED_IN_QUERY } from './LoginScreen'

export const ME_QUERY = gql`
    query Me($projectId: UUID) {
        me {
            id
            name
            email
            image
            isSuperuser
            usedProjectTokens(projectId: $projectId) {
                amount
                total
            }
        }
    }
`

const LOGOUT = gql`
    mutation Logout {
        logout
    }
`

export const UserAvatar = () => {
  const [logout] = useMutation(LOGOUT, {
    refetchQueries: [IS_LOGGED_IN_QUERY]
  })
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { t } = useTranslation('auth/user_header')

  const currentProject = useSelector((state) => state.currentProject.value)
  const currentUser = useSelector((state) => state.currentUser.value)

  const { data, loading, refetch } = useQuery(ME_QUERY, {
    pollInterval: 10 * 1000,
    variables: {
      projectId: currentProject?.id
    }
  })

  let usedTokens = 0

  if (
    data?.me?.usedProjectTokens?.amount > 0 &&
        data?.me?.usedProjectTokens?.total > 0
  ) {
    usedTokens =
            data?.me?.usedProjectTokens?.amount /
            data?.me?.usedProjectTokens?.total
  }

  useEffect(() => {
    if (!loading) {
      dispatch(setUser(data?.me))
    }
  }, [data?.me?.id, loading])

  if (!data) {
    return <Avatar src={currentUser?.image} size="lg" color="neutral" />
  }

  if (data.me?.id) {
    return (

            <Dropdown>
                <MenuButton sx={{
                  p: 0,
                  mt: 2,
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}>
                    <Box sx={{ position: 'relative', transform: 'scale(0.8)' }}>
                        <Avatar src={data?.me?.image} size="lg" />
                        <CircularProgress
                            color={usedTokens >= 1 ? 'danger' : 'primary'}
                            size="lg"
                            value={Math.min(usedTokens * 100, 100)}
                            determinate={true}
                            thickness={5}
                            sx={{
                              position: 'absolute',
                              top: -8,
                              left: -8,
                              zIndex: 1
                            }}
                        />
                    </Box>
                </MenuButton>
                <Menu>
                    {usedTokens
                      ? (
                            <MenuItem>
                                {t('used_tokens', {
                                  percent:
                                        Math.round(usedTokens * 10000) / 100,
                                  amount: Math.round((data?.me?.usedProjectTokens?.amount ?? 0) * 10000) / 100,
                                  total: Math.round((data?.me?.usedProjectTokens?.total ?? 0) * 10000) / 100
                                })}
                            </MenuItem>
                        )
                      : null}
                    <MenuItem
                        color="danger"
                        onClick={() =>
                          logout().then(() => {
                            refetch()
                            const cookies = new Cookies()
                            cookies.remove('ssbi_session', {
                              path: '/'
                            })
                            dispatch(clearUser())
                            navigate(0)
                          })
                        }
                    >{t`logout`}</MenuItem>
                </Menu>
            </Dropdown>
    )
  } else {
    return null
  }
}
